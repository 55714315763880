<template>
<v-container>
  <v-data-table
    :headers="headers"
    :items="transactions"
    class="elevation-1 my-auto"
    :search="search"
    :loading="loading"
    
  >
    <template v-slot:top>
      <v-card flat color="#598639" dark >
        <v-container>
        <v-row class="">
          <v-col cols="12" md="3" xl="2" align="left">
            <p class="text-sm-h5" align-sm="left" >{{$t("Transactions")}}</p>
          </v-col>
          
          <v-col cols="12" md="2" xl="3" align="left">
            <v-text-field
             dense
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              clearable
              
              
            ></v-text-field>
          </v-col>
         
          <v-col cols="12" md="3" xl="3">
            <v-menu
              dense
              v-model="dateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  v-model="datesToString"
                  :label="$t('select date range')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  
                  clearable
                  @click:clear="dates = []"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                range
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          
          <v-col cols="6" md="2" xl="2" align="right">
            <v-switch
            dense
            :label="exported ? $t('Unexported transactions') :$t('All transactions')"
            v-model="exported"
            color="white"
            class="mt-2"
            :true-value="1"
            :false-value="0"
            >

            </v-switch>
          </v-col>
          <v-col cols="6" md="2" xl="2" align="right">
            <xlsx-workbook>
              <xlsx-sheet
                :collection="transactions_tr"
                :sheet-name="`transactions`"
              />
              <xlsx-download
              :filename="`transactions_${datesToString || 'all'}.xlsx`"
              >
             
                <v-btn
                color="black"
                class="white--text"
                @click="markExported"
                >{{$t('Download')}}</v-btn>
              
                
              </xlsx-download>
            </xlsx-workbook>
          </v-col>
        </v-row>
        </v-container>
      </v-card>
    </template>
    <template v-slot:item.sent_to_fho="{ item }">
        <v-icon v-if="item.sent_to_fho === 1"   color="green"> mdi-check-bold </v-icon>
        <v-icon v-else-if="item.sent_to_fho === 0" color="red"> mdi-cancel </v-icon>
        <v-icon v-else  color="orange"> mdi-exclamation</v-icon>
    </template>
    <template v-slot:item.timestamp="{ item }">
        {{new Date(item.timestamp).toLocaleString()}}
    </template>
    <template slot="body.append">
      <tr>
          <th></th>
          <th></th> 
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        <th>
            <p>Sum: <strong class="text-h5 ml-2">{{quantityCount}}</strong></p>        
        </th>
      </tr>
    </template>
  </v-data-table>
</v-container>
</template>

<script>

import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
export default {
  name: "transactions",
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  },
  data() {
    return {
      dates: [],
      search: "",
      loading: false,
      exported: 0,
      transactions: [],
      report: [],
      headers: [
        {
          value: "id",
          text: this.$t("ID"),
          sortable: true,
          align: "start",
        },
        {
          value: "timestamp",
          sortable: true,
          text: this.$t("Date"),
          align: "center",
        },
        {
          value: "vname",
          text: this.$t("Vehicle ID"),
          sortable: true,
          align: "center",
        },
        {
          value: "vud3",
          text: this.$t("Vehicle Name"),
          align: "center",

          sortable: true,
        },
        {
          value: "dname",
          text: this.$t("Driver ID"),
          align: "center",

          sortable: true,
        },
        {
          value: "dud3",
          text: this.$t("Driver Name"),
          align: "center",

          sortable: true,
        },
        {
          text: this.$t("Dispenser"),
          value: "pump",
          sortable: true,
          align: "center",
        },
        {
          text: this.$t("Fuel Type"),
          value: "product_name",
          sortable: true,
          align: "center",
        },
        {
          text: this.$t("Quantity"),
          value: "quantity",
          sortable: true,
          align: "center",
        },
        {
          text: this.$t("Temp"),
          value: "temperature",
          sortable: true,
          align: "center",
        }, 
        {
          text: this.$t("Raw Quantity"),
          value: "raw_volume",
          sortable: true,
          align: "center",
        },
        {
          text: this.$t("Km/EH"),
          value: "odometer",
          sortable: true,
          align: "center",
        },
        {
          text: this.$t("Note"),
          value: "external_tran_id",
          sortable: true,
          align: "center",
        },
        {
          text: this.$t("Exported"),
          value: "sent_to_fho",
          sortable: true,
          align: "center",
        },
      ],
    }
  },

  computed: {
    transactions_tr () {
      let a = []
      for(let t of this.transactions) {
        let r = {}
        for(const [k, v] of Object.entries(t)) {
          r[this.$t(k)] = v
        }
        a.push(r) 
      }
      return a
    },
    
    datesToString: {
      get() { 
        return this.dates.join(" ~ ")
      },
      set(val) {
        console.log("settet dateto string", val)
      }
    },
    quantityCount() {
      let i = this.transactions.reduce(( acc, i)=>  acc + i.quantity ,0 )
      return i.toFixed(2)
    },
    dateMenu: {
      get() {
        if (this.dates.length == 0 || this.dates.length > 1) {
          return false

        } 
        return true
      },
      set(val) {
        console.log("setter dateMenu", val)
      }
    }
  },
  watch: {
    dates(nval, val) {
      console.log(nval, val)
      if(nval.length == 2) {
        
        this.getTimeFiletred()
      }
      if(nval.length == 0) {
        this.initialize()
      }
    },
    exported(nval) {
      if(nval == 0) {
        this.getTimeFiletred()
      }
      if(nval == 1 && this.dates.length == 2) {
        this.getTimeFiletred()
      }
      if(nval == 1 && this.dates.length == 0) {
        this.getTimeFiletred()
      }
    }

  },

  methods: {
    async initialize() {
      let res = null
      try {
          this.loading = true
          res = await this.$http.get("/transaction")
          this.transactions = res.data
        } catch (e) {
          console.log(e)
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.loading = false
        } finally {
          this.loading = false
        }
    },
    async getTimeFiletred () {
      let res = null
      try {
          this.loading = true
          res = await this.$http.get("/transaction", {
            params: {
              from: this.dates[0],
              to: this.dates[1],
              exported: this.exported
            }
          })
          this.transactions = res.data
        } catch (e) {
          console.log(e)
          this.loading = false
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
        } finally {
          this.loading = false
        }
    },
   
    async markExported() {
      try {
          this.loading = true
          await this.$http.put("/transaction", this.transactions_tr.map(({ID}) => ID))        
        } catch (e) {
          console.log(e)
          this.loading = false
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
        } finally {
          this.loading = false
          this.initialize()
          this.exported = 1
        }
    }
    
  },

  created() {
    this.initialize()
  }
}

</script>

